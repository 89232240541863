// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;



//
//@import "./node_modules/bootstrap/scss/functions"; // Required
//@import "./node_modules/bootstrap/scss/variables"; // Required
//@import "./node_modules/bootstrap/scss/mixins"; // Required
////
//@import "./node_modules/bootstrap/scss/root"; // Required
//@import "./node_modules/bootstrap/scss/reboot"; // Required
//@import "./node_modules/bootstrap/scss/type";
// @import "./node_modules/bootstrap/scss/images";
// //@import "./node_modules/bootstrap/scss/code";
//@import "./node_modules/bootstrap/scss/grid";
// @import "./node_modules/bootstrap/scss/tables";
// @import "./node_modules/bootstrap/scss/forms";
//@import "./node_modules/bootstrap/scss/buttons";
//@import "./node_modules/bootstrap/scss/transitions";
// @import "./node_modules/bootstrap/scss/dropdown";
// @import "./node_modules/bootstrap/scss/button-group";
// //@import "./node_modules/bootstrap/scss/input-group";
// @import "./node_modules/bootstrap/scss/mixins/container";
// @import "./node_modules/bootstrap/scss/nav";
// @import "./node_modules/bootstrap/scss/navbar"; // Requires nav
// @import "./node_modules/bootstrap/scss/card";
// @import "./node_modules/bootstrap/scss/breadcrumb";
// @import "./node_modules/bootstrap/scss/pagination";
// @import "./node_modules/bootstrap/scss/badge";
// //@import "./node_modules/bootstrap/scss/jumbotron";
// @import "./node_modules/bootstrap/scss/alert";
// @import "./node_modules/bootstrap/scss/progress";
// @import "./node_modules/bootstrap/scss/mixins";
// @import "./node_modules/bootstrap/scss/list-group";
//@import "./node_modules/bootstrap/scss/close";
// @import "./node_modules/bootstrap/scss/toasts";
//@import "./node_modules/bootstrap/scss/modal"; // Requires transitions
// @import "./node_modules/bootstrap/scss/tooltip";
// @import "./node_modules/bootstrap/scss/popover";
// @import "./node_modules/bootstrap/scss/carousel";
// @import "./node_modules/bootstrap/scss/spinners";
//@import "./node_modules/bootstrap/scss/utilities";
 //@import "./node_modules/bootstrap/scss/print";


//
// Custom styles
//

//@import "icon-list";

body {
  //padding: 3rem 1.5rem;
  font-family: "Roboto",  Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

// Style Bootstrap icons
.bi {
  //fill: currentColor;
}
